import React from "react"
import { Link } from "gatsby"
import { FaCreativeCommons, FaCreativeCommonsBy, FaGitlab } from "react-icons/fa"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1 className="text-5xl font-black font-sans mb-10 mt-0">
          <Link className="shadow-none" to={`/`}>
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3 className="text-2xl font-sans font-black mt-0">
          <Link className="shadow-none" to={`/`}>
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <div className="max-w-2xl mx-auto px-5 py-10">
        <header>{header}</header>
        <main>{children}</main>
        <footer className="mt-20 lg:mt-40">
          <div className="flex flex-row flex-wrap m-2 items-center text-lg">
            <div className="flex w-100 mx-auto">
              <FaCreativeCommonsBy/>
              <span className="w-3"/>
              <FaCreativeCommons/>
              <span className="w-3"/>
              <FaGitlab/>
            </div>
            <span className="text-center text-sm mt-5">{new Date().getFullYear()}, hecho en un fin de semana gris con
              {` `}
              <a className="text-blue-600" href="https://www.gatsbyjs.org">
            Gatsby.
          </a>{` `}
              Los textos y las fotos son Creative Commons. Podes copiar el proyecto en <a className="text-blue-600"
                                                                                          href="https://gitlab.com/raulsperoni/recetario"
                                                                                          target="_blank">Gitlab</a></span>
          </div>
        </footer>
      </div>
    )
  }
}

export default Layout
